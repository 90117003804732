import {SectionStoreyDto} from './section-storey.dto';

export interface SectionVariantDto {
  readonly id: string;
  readonly gridId: string;
  readonly topologyId: string;
  readonly mainSegmentSlotGroupId: string;
  readonly sectionId: string;
  readonly sectionVariantId: string;
  readonly stories: SectionStoreyDto[];
  readonly coefficientK0: number;
  readonly rank: number;
  readonly averageApartmentArea: number;
  readonly effectiveArea: number;
}
