export type HvacTypicalStoreyRoomHeatLossConfigDto = {
  readonly apartmentFacadeWallHeatTransferCoefficient: number;
  readonly lluFacadeWallHeatTransferCoefficient: number;
  readonly windowHeatTransferCoefficient: number;
  readonly interApartmentDoorHeatTransferCoefficient: number;
  readonly interRoomDoorHeatTransferCoefficient: number;
  readonly innerWallHeatTransferCoefficient: number;
  readonly floorHeatTransferCoefficient: number;
  readonly ceilingHeatTransferCoefficient: number;
  readonly ceilingLLUHeatTransferCoefficient: number;
};
