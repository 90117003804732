import {GenerationTaskStatusEnum, GenerationTaskSubsystemType} from '../../enums';

export interface GenerationVariantDto {
  readonly id: string;
  readonly generationTaskId: string;
  readonly data: any;
  readonly subsystem: GenerationTaskSubsystemType;
  readonly objectModelId: string;
  readonly authorId: string;
  readonly lastEditorId: string;

  readonly status: GenerationTaskStatusEnum;

  /** Пустую строку воспринимать как null */
  readonly errorTitle: string;
  /** Пустую строку воспринимать как null */
  readonly errorMessage: string;

  /**
   * ISO 8601 UTC Date string
   * @example "2024-04-19T10:05:19.023412Z"
   */
  readonly createdAt: string;
  /**
   * ISO 8601 UTC Date string
   * @example "2024-04-19T10:05:19.023412Z"
   */
  readonly lastModifiedAt: string;
}
