export type SubsystemCode =
  | 'Layout'
  | 'Structure'
  | 'Structure2'
  | 'HVAC'
  | 'HVAC2'
  | 'Plumbing'
  | 'Plumbing2'
  | 'Electrical'
  | 'Electrical2'
  | 'SpacePlanning'
  | 'OMAR'
  | 'OMAR2';
