import {AsyncPipe, NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {TuiButton, TuiDataList, TuiIcon, TuiLoader} from '@taiga-ui/core';

import {ActionButtonEnum} from './enums';

@Component({
  standalone: true,
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TuiIcon, AsyncPipe, NgIf, TuiLoader, TuiDataList, TuiButton],
})
export class ActionButtonComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _pending: boolean;

  get pending(): boolean {
    return this._pending;
  }

  @Input()
  set pending(value: boolean) {
    this._pending = value;
  }

  @Output()
  action = new EventEmitter<ActionButtonEnum>();

  icon: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _type: ActionButtonEnum;

  @Input()
  set type(value: ActionButtonEnum) {
    this._type = value;

    switch (value) {
      case ActionButtonEnum.Edit:
        this.icon = '@tui.pencil';
        this.name = 'Редактировать';
        break;
      case ActionButtonEnum.Delete:
        this.icon = '@tui.trash-2';
        this.name = 'Удалить';
        break;
    }
  }
}
