import {FormControl, FormGroup} from '@angular/forms';
import {BuildingType} from '@projects-api-v2/util';
import {SelectOption} from '@shared/util';

export type EntryFormControls = {
  readonly name: FormControl<string | null>;
  readonly portfolioId: FormControl<string | null>;
  readonly description: FormControl<string | null>;
  readonly country: FormControl<string | null>;
  readonly region: FormControl<string | null>;
  readonly city: FormControl<string | null>;
  readonly postalCode: FormControl<string | null>;
  readonly rawAddress: FormControl<string | null>;
  readonly latitude: FormControl<number | null>;
  readonly longitude: FormControl<number | null>;
  readonly altitude: FormControl<number | null>;
  readonly streetName: FormControl<string | null>;
  readonly rotationAngle: FormControl<number | null>;
  readonly buildingType: FormControl<SelectOption<BuildingType> | null>;
  readonly previewImageName: FormControl<string | null>;
  readonly middleImageName: FormControl<string | null>;
  readonly largeImageName: FormControl<string | null>;
};

export type EntryFormGroup = FormGroup<EntryFormControls>;

export type EntryFormValue = {
  readonly name: string | null;
  readonly portfolioId: string | null;
  readonly description: string | null;
  readonly country: string | null;
  readonly region: string | null;
  readonly city: string | null;
  readonly postalCode: string | null;
  readonly rawAddress: string | null;
  readonly latitude: number | null;
  readonly longitude: number | null;
  readonly altitude: number | null;
  readonly streetName: string | null;
  readonly rotationAngle: number | null;
  readonly buildingType: SelectOption<BuildingType> | null;
  readonly previewImageName: string | null;
  readonly middleImageName: string | null;
  readonly largeImageName: string | null;
};
