import {
  CustomBuildSettings,
  HedgehogWallCreationSystemSettingsDto,
  IfcGeometryBuilderSettingsDto,
  LluCreationSettingsDto,
  ObjectModelBuilderSettingsDto,
  ShareWrapWallCreationSystemSettings,
  StoreyWallGroupSettings,
} from '@structure-api/util';

export interface ObjectModelBuilderOptionsDto {
  id?: string;
  objectModelBuilderSettings?: ObjectModelBuilderSettingsDto;
  ifcGeometryBuilderSettings?: IfcGeometryBuilderSettingsDto | null;
  lluCreationSettings?: LluCreationSettingsDto;
  shareWrapWallCreationSystemSettings?: ShareWrapWallCreationSystemSettings;
  hedgehogWallCreationSystemSettings?: HedgehogWallCreationSystemSettingsDto;
  additionalWallCreationSystemSettings?: {
    isolineToWallDistance: number;
  };
  storeyWallGroupSettings?: StoreyWallGroupSettings;
  customBuildSettings?: CustomBuildSettings;
}
