import {Clipboard} from '@angular/cdk/clipboard';
import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {getVariantsCountInSubsystem, SubsystemsMap, SubsystemVariantsMap} from '@compare/util';
import {RouterState} from '@ngxs/router-plugin';
import {Action, createSelector, State, StateContext, Store} from '@ngxs/store';
import {SubsystemCode} from '@shared/util';
import {TuiAlertService} from '@taiga-ui/core';
import {ExtendedRouterState} from '@util/custom-router-serializer';
import {EMPTY} from 'rxjs';

import {
  AddVariant,
  ClearAll,
  ClearList,
  CopyShareLink,
  DestroyView,
  InitFromLink,
  InitFromStorage,
  InitView,
  RemoveModel,
  RemoveTask,
} from './actions';

const MAX_COMPARE_COUNT = 20;

type VariantsCount = {
  [subsystemCode in SubsystemCode]?: number;
};

export type State = {
  [projectId: string]: SubsystemsMap;
};

export const defaultState: State = {};

@Injectable()
@State<State>({
  name: 'compareProjectView',
  defaults: defaultState,
})
export class CompareProjectViewState {
  private readonly alerts = inject(TuiAlertService);
  private readonly store = inject(Store);
  private readonly clipboard = inject(Clipboard);
  private readonly router = inject(Router);

  static variantsOf(projectId: string, subsystemCode: SubsystemCode) {
    return createSelector([CompareProjectViewState], (state: State): SubsystemVariantsMap => {
      return state[projectId] ? state[projectId][subsystemCode] || {} : {};
    });
  }

  static countOf(projectId: string, subsystemCode: SubsystemCode) {
    return createSelector([CompareProjectViewState.totalCount(projectId)], (subsystemVariantsCount: VariantsCount) => {
      return subsystemVariantsCount[subsystemCode] || 0;
    });
  }

  static variants(projectId: string) {
    return createSelector([CompareProjectViewState], (state: State) => {
      return state[projectId];
    });
  }

  static totalCount(projectId: string) {
    return createSelector([CompareProjectViewState], (state: State) => {
      const projectState = state[projectId];

      if (projectState === undefined) {
        return {};
      }

      const subsystemCodes = Object.keys(projectState) as SubsystemCode[];

      return subsystemCodes.reduce(
        (acc, subsystemCode) => {
          return {
            ...acc,
            [subsystemCode]: getVariantsCountInSubsystem(projectState[subsystemCode]),
          };
        },
        <VariantsCount>{},
      );
    });
  }

  @Action(InitView)
  initView(ctx: StateContext<State>) {
    const routeState = this.store.selectSnapshot(RouterState.state<ExtendedRouterState>);

    if (routeState && routeState.params && routeState.params.sharedList !== undefined) {
      return ctx.dispatch([new InitFromLink()]);
    }

    return ctx.dispatch([new InitFromStorage()]);
  }

  @Action(DestroyView)
  destroyView(ctx: StateContext<State>) {
    return ctx.dispatch([new InitFromStorage()]);
  }

  @Action(InitFromStorage)
  initFromStorage(ctx: StateContext<State>) {
    const previousStateJSONString = localStorage.getItem('variantsComparing');

    if (previousStateJSONString !== null) {
      ctx.setState(<State>JSON.parse(previousStateJSONString));
    }

    return EMPTY;
  }

  @Action(InitFromLink)
  initFromLink(ctx: StateContext<State>) {
    const state = ctx.getState();
    const routeState = this.store.selectSnapshot(RouterState.state<ExtendedRouterState>);

    const subsystemCode: SubsystemCode = routeState.data?.subsystemCode;
    const projectId: string = routeState.pathParams?.projectId;

    const existedSubsystems = state[projectId] || {};

    let sharedList = null;

    try {
      sharedList = JSON.parse(routeState.params.sharedList);
    } catch {
      return this.alerts.open('Не удалось получить необходимые данные из ссылки', {
        label: 'Ссылка сломана',
        appearance: 'error',
      });
    }

    ctx.patchState({
      [projectId]: {
        ...existedSubsystems,
        [subsystemCode]: sharedList,
      },
    });

    return EMPTY;
  }

  /**
   * {cancelUncompleted: true} - чтобы сообщения об ошибке не стакались бесконечно
   */
  @Action(AddVariant, {cancelUncompleted: true})
  addVariant(ctx: StateContext<State>, payload: AddVariant) {
    const state = ctx.getState();
    const {projectId, subsystemCode, generationTaskId, modelExternalId} = payload;
    const existedSubsystems = state[projectId] || {};
    const existedVariantsMap = existedSubsystems[subsystemCode] || {};
    const existedVariants = existedVariantsMap[generationTaskId] || [];

    if (getVariantsCountInSubsystem(existedVariantsMap) === MAX_COMPARE_COUNT) {
      return this.alerts.open('Для сравнения можно выбрать не\u00A0более 20\u00A0вариантов для одной подсистемы', {
        appearance: 'info',
        label: 'Не\u00A0удалось добавить',
      });
    }

    ctx.patchState({
      [projectId]: {
        ...existedSubsystems,
        [subsystemCode]: {
          ...existedVariantsMap,
          [generationTaskId]: [...new Set([...existedVariants, modelExternalId]).values()],
        },
      },
    });

    localStorage.setItem('variantsComparing', JSON.stringify(ctx.getState()));

    return EMPTY;
  }

  @Action(RemoveTask)
  removeTask(ctx: StateContext<State>, payload: RemoveTask) {
    const state = ctx.getState();
    const {projectId, subsystemCode, generationTaskId} = payload;
    const existedSubsystems = state[projectId] || {};
    const generationTasks = existedSubsystems[subsystemCode];

    delete generationTasks[generationTaskId];

    ctx.patchState({
      [projectId]: {
        ...existedSubsystems,
        [subsystemCode]: {
          ...generationTasks,
        },
      },
    });

    localStorage.setItem('variantsComparing', JSON.stringify(ctx.getState()));
  }

  @Action(RemoveModel)
  removeModel(ctx: StateContext<State>, payload: RemoveModel) {
    const state = ctx.getState();
    const {projectId, subsystemCode, generationTaskId, modelExternalId} = payload;
    const existedSubsystems = state[projectId] || {};
    const generationTasks = existedSubsystems[subsystemCode];
    const result = generationTasks[generationTaskId].filter(id => id !== modelExternalId);

    if (result.length === 0) {
      delete generationTasks[generationTaskId];

      ctx.patchState({
        [projectId]: {
          ...existedSubsystems,
          [subsystemCode]: {
            ...generationTasks,
          },
        },
      });
    } else {
      ctx.patchState({
        [projectId]: {
          ...existedSubsystems,
          [subsystemCode]: {
            ...generationTasks,
            [generationTaskId]: result,
          },
        },
      });
    }

    localStorage.setItem('variantsComparing', JSON.stringify(ctx.getState()));
  }

  @Action(ClearList)
  clearList(ctx: StateContext<State>, payload: ClearList) {
    const state = ctx.getState();
    const {projectId, subsystemCode} = payload;

    ctx.patchState({
      [projectId]: {
        ...state[projectId],
        [subsystemCode]: {},
      },
    });

    localStorage.setItem('variantsComparing', JSON.stringify(ctx.getState()));
  }

  @Action(CopyShareLink)
  copyShareLink(_ctx: StateContext<State>) {
    const routerState = this.store.selectSnapshot(RouterState.state<ExtendedRouterState>);
    const subsystemCode: SubsystemCode = routerState?.data.subsystemCode;
    const projectId: string = routerState?.pathParams.projectId;
    const variants = this.store.selectSnapshot(CompareProjectViewState.variantsOf(projectId, subsystemCode));
    const shareLink = this.router.createUrlTree([
      routerState.url,
      {
        sharedList: JSON.stringify(
          Object.entries(variants).reduce(
            (acc, [generationTaskId, variantsIds]) => ({...acc, [generationTaskId]: variantsIds}),
            {},
          ),
        ),
      },
    ]);

    this.clipboard.copy(`${window.location.origin}/${shareLink.toString()}`);

    return this.alerts.open('Ссылка скопирована', {appearance: 'success'}).subscribe();
  }

  @Action(ClearAll)
  clearAll(ctx: StateContext<State>) {
    ctx.setState({...defaultState});

    localStorage.setItem('variantsComparing', JSON.stringify(ctx.getState()));
  }

  ngxsOnInit(ctx: StateContext<State>) {
    ctx.dispatch(new InitFromStorage());
  }
}
