<tui-loader class="loader" [inheritColor]="true" [overlay]="true" [showLoader]="pending">
  <button
    type="button"
    tuiOption
    role="menuitemradio"
    appearance="flat"
    size="s"
    class="table-action-button"
    (click)="action.emit(_type)"
  >
    <span>{{ name }}</span>
    <tui-icon class="tui-space_left-2" [icon]="icon" />
  </button>
</tui-loader>
