import {GenerationTaskSubsystemType} from '../../enums';

export interface GenerationTaskCreateBodyDto<D = string> {
  data: D;
  subsystem: GenerationTaskSubsystemType;
  projectId: string;
  parentId?: string;
  sourceVariantId?: string;
  description?: string;
}
