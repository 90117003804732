import {GenerationTaskStatusEnum, GenerationTaskSubsystemType} from '@generation-api-v2/util/enums';

export type GenerationTaskMapFilterDto = {
  showDeleted?: boolean;
  subsystem?: GenerationTaskSubsystemType;
  userIds?: string[];
  generationTaskIds?: string[];
  rangeTime?: {
    /**
     * ISO 8601 UTC Date string
     * @example "2024-04-19T10:05:19.023412Z"
     */
    from: string;
    /**
     * ISO 8601 UTC Date string
     * @example "2024-04-19T10:05:19.023412Z"
     */
    to: string;
  };
  statuses?: GenerationTaskStatusEnum[];
  description?: string;
};
