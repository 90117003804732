import {LayoutGenerationTaskGenerationData} from './generation-task-data.dto';

export type LayoutPresetParam<T = number> = {
  readonly value?: T;
  readonly min?: number;
  readonly max?: number;
};

export type LayoutPresetTaskDataConfig = {
  readonly [fieldKey in keyof LayoutGenerationTaskGenerationData]: LayoutPresetParam<
    LayoutGenerationTaskGenerationData[fieldKey]
  >;
};

export interface LayoutPresetDto {
  readonly id: number;
  readonly name: string;

  /** Объект с настройками ограничений любого из свойств задания */
  readonly taskDataConfig: LayoutPresetTaskDataConfig;

  /** Массив айдюков доступных для выбора в данном пресете */
  readonly presetAxisId: readonly number[];
}
