import {HvacFireResistanceLimit} from '../../hvac';

export type HvacDuctInsulationsConfigDto = {
  readonly verticalDuctApartmentVentilation: {
    readonly thickness: number;
    readonly fireResistanceLimit: HvacFireResistanceLimit;
  };
  readonly horizontalDuctApartmentVentilationFromValveToWall: {
    readonly thickness: number;
    readonly fireResistanceLimit: HvacFireResistanceLimit;
  };
  readonly verticalDuctInsideEngineeringZoneinSharedAreas: {
    readonly thickness: number;
    readonly fireResistanceLimit: HvacFireResistanceLimit;
  };
  readonly smokeVentilationDuctsFromValveToFan: {
    readonly thickness: number;
    readonly fireResistanceLimit: HvacFireResistanceLimit;
  };
  readonly fireSafeZoneDucts: {
    readonly thickness: number;
    readonly fireResistanceLimit: HvacFireResistanceLimit;
  };
};
