import {ProjectSubsystem} from '../types';

export const ROUTES_SUBSYSTEM_MAP: {[routePath: string]: ProjectSubsystem} = {
  layout: 'Layout',
  structure: 'Structure',
  structure2: 'Structure2',
  hvac: 'HVAC',
  plumbing: 'Plumbing',
  electrical: 'Electrical',
  spacePlanning: 'SpacePlanning',
  architecture: 'OMAR',
  architecture2: 'OMAR2',
};
