import {EomLightingDto, EomSwitchDto} from '../../../types';
import {EomBoxesDto} from './eom-boxes.dto';
import {EomEngineeringSubZoneDto} from './eom-engineering-sub-zone.dto';
import {EomEquipmentDto} from './eom-equipment.dto';
import {EomFireSecSubZoneConfigurationModelDto} from './eom-fire-sec-sub-zone-configuration-model.dto';
import {EomReceptacleDto} from './eom-receptacle.dto';

export interface EomConfigDto {
  readonly objectModel: {
    readonly receptacle: EomReceptacleDto;
    readonly switch: EomSwitchDto;
    readonly lighting: EomLightingDto;
    readonly equipment: EomEquipmentDto;
    readonly boxes: EomBoxesDto;
    readonly engineeringSubZone: EomEngineeringSubZoneDto;
  };
  readonly coordinator: {
    readonly fireSecSubZones: {
      readonly fireSecElectrical: EomFireSecSubZoneConfigurationModelDto;
      readonly fireSecPublicAddressGeneralAlarmSystems: EomFireSecSubZoneConfigurationModelDto;
      readonly fireSecAddressableCommunicationLine1: EomFireSecSubZoneConfigurationModelDto;
      readonly fireSecAddressableCommunicationLine2: EomFireSecSubZoneConfigurationModelDto;
    };
  };
}
