export type EomFireSecZoneWidth =
  | 225
  | 250
  | 275
  | 300
  | 325
  | 350
  | 375
  | 400
  | 425
  | 450
  | 475
  | 500
  | 525
  | 550
  | 575;
