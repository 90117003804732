<h2 *ngIf="data.code">{{ data.code }}</h2>
<h3 *ngIf="data.title">{{ data.title }}</h3>
<ng-container *ngFor="let item of data.messages">
  <tui-notification size="m" appearance="error" class="tui-space_vertical-4">
    <div>
      <div class="message" [innerHTML]="item"></div>
    </div>
  </tui-notification>
  <button tuiButton [appearance]="'secondary'" [iconStart]="'@tui.copy'" [size]="'m'" (click)="copyToClipboard(item)">
    Скопировать детальный лог
  </button>
</ng-container>
@if (needHelp) {
  <div class="help">
    <ntc-info-btn />
  </div>
}
