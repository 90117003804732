import {LayoutTaskOptionsCreateBody} from '@layout-api/util';

import {GenerationTaskDataBodySeriallized} from '../generation-task-data-body-serialized.dto';

export type LayoutGenerationTaskReferenceFields = 'slots' | 'apartmentTypes';
export type LayoutGenerationTaskGenerationCreateData = Omit<
  LayoutTaskOptionsCreateBody,
  LayoutGenerationTaskReferenceFields
>;
export type LayoutGenerationTaskHardcodeCreateData = Pick<
  LayoutTaskOptionsCreateBody,
  LayoutGenerationTaskReferenceFields
>;
export type LayoutGenerationTaskCreateDataDto = GenerationTaskDataBodySeriallized<
  LayoutGenerationTaskGenerationCreateData,
  LayoutGenerationTaskHardcodeCreateData
>;
