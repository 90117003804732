import {SectionVariantDto} from './section-variant.dto';
import {SelectedSectionDto} from './selected-section.dto';

export interface GetSectionVariantsResponseDto {
  readonly sectionId: string;
  readonly path: string[];
  readonly items: readonly SectionVariantDto[];
  readonly selectedSections: readonly SelectedSectionDto[];
  readonly totalItemsCount: number;
}
