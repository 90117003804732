/**
 * Тип скачиваемого скетчплана для этапа Финал.
 * Относится к АР:
 * EP - План стадии ЭП,
 * PRFR  - Сводный План стадии ПРФР,
 * PDTypicalFloor - План типового этажа ПД,
 * PD2 - План ОДИ стадии ПД,
 * PD3 - План КР2.1 стадии ПД,
 * RD - План типового этажа РД
 *
 * Относится к КР:
 * VerticalDesigns - План вертикальных конструкций,
 * HorizontalDesigns - План горизонтальных конструкций
 *
 * @alias 'Platforma.Control.Generation.Api.Controllers.V2.Output.PlanTypeDto'
 * */
export type SketchPlan =
  | 'EP'
  | 'PRFR'
  | 'PDTypicalFloor'
  | 'PD2'
  | 'PD3'
  | 'RD'
  | 'VerticalDesigns'
  | 'HorizontalDesigns';
