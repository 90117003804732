import {SelectOption} from './select-option';

export type CatalogSelectOption = SelectOption<
  string,
  {
    version: number;
    nick: string;
    description?: string;
  }
>;
