import {TaskOptionsDto} from '@layout-api/util';

import {LayoutGenerationTaskReferenceFields} from '../../../types';
import {GenerationTaskDataDto} from '../generation-task-data.dto';

export type LayoutGenerationTaskGenerationData = Omit<TaskOptionsDto, LayoutGenerationTaskReferenceFields>;
export type LayoutGenerationTaskHardcodeData = Pick<TaskOptionsDto, LayoutGenerationTaskReferenceFields>;
export type LayoutGenerationTaskDataDto = GenerationTaskDataDto<
  LayoutGenerationTaskGenerationData,
  LayoutGenerationTaskHardcodeData
>;
