import {EntryFormUsage} from '@portfolio/util/types/entry-form/entry-form-usage';
import {PortfolioDto} from '@projects-api-v2/util';
import {SidePanelOptions} from '@shared/ui';
import {TuiPopover} from '@taiga-ui/cdk';
/**
 * Входные и выходные свойства боковой панели с формой создания
 * редактирования справочника
 */
export type EntryFormDialogOptions = TuiPopover<
  SidePanelOptions<{portfolio: PortfolioDto | null; entryFormUsage: EntryFormUsage}>,
  PortfolioDto | null
>;
